<script setup lang="ts">
    import { Notivue, NotivueSwipe, Notification, NotificationProgress, lightTheme, slateTheme, outlinedIcons } from 'notivue'
    import ConfirmationDialog from '@/app/ConfirmationDialog.vue'
    import RenderingPage from '@/app/RenderingPage.vue'

    const coreStore = useCoreStore()

    onMounted(() => {
        window.Echo.channel('update-cache').listen('CacheCleared', () => {
            coreStore.fetchCoreData(true)
        })
    })

    onBeforeUnmount(() => {
        window.Echo.channel('update-cache').stopListening('CacheCleared')
    })

    useHead({
        title: 'App',
        titleTemplate: '%s · Resol',
    })
</script>

<template>
    <router-view />

    <notivue v-slot="item">
        <notivue-swipe :item="item">
            <notification :item="item" :theme="coreStore.darkMode ? slateTheme : lightTheme" :icons="outlinedIcons">
                <notification-progress :item="item" />
            </notification>
        </notivue-swipe>
    </notivue>

    <confirmation-dialog />

    <rendering-page />
</template>
