import axios from 'axios'
import axiosRetry from 'axios-retry'
import { getCookie } from 'typescript-cookie'

const token = getCookie('token')

window.axios = axios.create({
    withCredentials: true,
    headers: {
        Authorization: token ? `Bearer ${token}` : null,
    },
})

// Response interceptor
window.axios.interceptors.response.use(
    (response) => response,

    async (error) => {
        const status = error?.response?.status || 500
        const message = await getErrorMessage(error)

        // reload page if missing js file and reject promise
        if (status === 503) {
            window.location.reload()

            return Promise.reject(error)
        }

        if (status >= 500 || status == 429 || status == 405) {
            renderErrorMessage(status, message)
        }

        if (status === 422 || status === 400) {
            pushNotification.error(message)
        }

        if (status === 404) {
            pushNotification.error({
                title: '404 - No encontrado',
                message,
            })
        }

        if (status === 403) {
            pushNotification.error({
                title: '403 - No autorizado',
                message,
            })
        }

        if (status === 401) {
            const authStore = useAuthStore()

            authStore.logoutCleaner()

            pushNotification.info('Inicie sesión nuevamente')
        }

        return Promise.reject(error)
    },
)

axiosRetry(window.axios, {
    retries: 2,
    retryDelay: axiosRetry.exponentialDelay,
    retryCondition: axiosRetry.isNetworkError,
})

async function getErrorMessage(error: any) {
    const data =
        error?.request?.responseType === 'blob' && error?.response?.data instanceof Blob && error?.response?.data?.type?.toLowerCase().indexOf('json') != -1
            ? JSON.parse(await error.response.data.text())
            : error?.response?.data

    return data?.message || 'Algo salió mal'
}

function renderErrorMessage(title: string | number, message: string): void {
    useShowErrorDialog(String(title), message)
}
