import type { RouteLocationRaw, RouteRecordName, RouteParamValueRaw } from 'vue-router'

export function useRouterPush() {
    function routerPush(routeName: RouteRecordName, id?: RouteParamValueRaw): void {
        const pushLocation: RouteLocationRaw = {
            name: routeName,
        }

        if (id) {
            pushLocation['params'] = { id }
        }

        window.router.push(pushLocation)
    }

    return {
        routerPush,
    }
}
