import type { RouterContext } from '../router'

export default async ({ to, next }: RouterContext) => {
    const authStore = useAuthStore()

    if (!authStore.check && authStore.token && to.name != 'Login') {
        await authStore.fetchUser()
    }

    return next()
}
