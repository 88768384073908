import type { RouterContext } from '../router'

export default ({ next }: RouterContext) => {
    const authStore = useAuthStore()

    if (authStore.admin) {
        return next()
    }

    console.info('to dashboard from admin area')

    return next({ name: 'Dashboard' })
}
