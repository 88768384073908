import type { RouteRecordRaw } from 'vue-router'

import authMiddleware from '@/router/middleware/authMiddleware'
import adminMiddleware from '@/router/middleware/adminMiddleware'
import guestMiddleware from '@/router/middleware/guestMiddleware'

export default <RouteRecordRaw[]>[
    /* ------------ */
    /* Start Layout */
    /* ------------ */
    {
        path: '/',
        component: () => import('@/layouts/StartLayout.vue'),
        meta: { middleware: [guestMiddleware] },
        children: [
            { path: '/', name: 'Inicio', component: () => import('@/pages/WelcomePage.vue') },
            /* ------------ */
            /* Login Routes */
            /* ------------ */
            { path: '/login', name: 'Login', component: () => import('@/pages/auth/LoginPage.vue') },
        ],
    },

    /* ------------- */
    /*  Main Layout  */
    /* ------------- */
    {
        path: '/app',
        component: () => import('@/layouts/MainLayout.vue'),
        meta: { middleware: [authMiddleware] },
        children: [
            { path: '/dashboard', name: 'Dashboard', component: () => import('@/pages/DashboardPage.vue') },
            { path: '/test', name: 'Test', component: () => import('@/pages/TestPage.vue') },
            /* Pedidos Route */
            { path: '/pedidos', name: 'Pedidos', component: () => import('@/pages/pedidos/PedidosPage.vue') },
            { path: '/pedidos/nuevo', name: 'NuevoPedido', component: () => import('@/pages/pedidos/nuevo/NuevoPedido.vue') },
            { path: '/pedidos/:id', name: 'DocumentoPedido', component: () => import('@/pages/pedidos/DocumentoPedido.vue'), props: true },
            /* Presupuestos Route */
            { path: '/presupuestos', name: 'Presupuestos', component: () => import('@/pages/presupuestos/PresupuestosPage.vue') },
            { path: '/presupuestos/:id', name: 'DocumentoPresupuesto', component: () => import('@/pages/presupuestos/DocumentoPresupuesto.vue'), props: true },
            /* Precios de Lista */
            { path: '/precios', name: 'Precios', component: () => import('@/pages/precios/PreciosPage.vue') },
            /* Settings Route */
            { path: '/perfil', name: 'Profile', component: () => import('@/pages/settings/ProfilePage.vue') },
            /* ------------ */
            /* Admin Routes */
            /* ------------ */
            {
                path: '/admin',
                meta: { middleware: [adminMiddleware] },
                children: [
                    /* Import Routes */
                    { path: '/admin/importar', name: 'Importar', component: () => import('@/pages/admin/imports/ImportPage.vue') },
                    { path: '/admin/listas', name: 'Listas', component: () => import('@/pages/admin/listas/ListasPage.vue') },
                    { path: '/admin/users', name: 'Users', component: () => import('@/pages/admin/user/UsersPage.vue') },
                    { path: '/admin/users/:id', name: 'DocumentoUser', component: () => import('@/pages/admin/user/DocumentoUser.vue'), props: true },
                    { path: '/admin/clientes', name: 'Clientes', component: () => import('@/pages/admin/clientes/ClientesPage.vue') },
                    { path: '/admin/vendedores', name: 'Vendedores', component: () => import('@/pages/admin/vendedores/VendedoresPage.vue') },
                    { path: '/admin/articulos', name: 'Articulos', component: () => import('@/pages/admin/articulos/ArticulosPage.vue') },
                    { path: '/admin/articulos/productos', name: 'Productos', component: () => import('@/pages/admin/articulos/productos/ProductosPage.vue') },
                    { path: '/admin/articulos/colores', name: 'Colores', component: () => import('@/pages/admin/articulos/colores/ColoresPage.vue') },
                    { path: '/admin/articulos/medidas', name: 'Medidas', component: () => import('@/pages/admin/articulos/medidas/MedidasPage.vue') },
                ],
            },
        ],
    },
    /* Error Routes */
    {
        path: '/error',
        component: () => import('@/layouts/StartLayout.vue'),
        children: [
            { path: '/actualizando', name: '503', component: () => import('@/pages/errors/ErrorPage503.vue') },
            { path: '/:pathMatch(.*)*', name: '404', component: () => import('@/pages/errors/ErrorPage404.vue') },
        ],
    },
]
