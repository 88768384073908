import type { RouterContext } from '../router'

export default ({ next }: RouterContext) => {
    const authStore = useAuthStore()

    if (authStore.check) {
        return next()
    }

    console.info('to login from auth area')

    return next({ name: 'Login' })
}
