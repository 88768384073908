export function useShowDialog(newDialogState: Dialog.DialogState): void {
    const dialogStore = useDialogStore()

    dialogStore.showDialog(newDialogState)
}

export function useShowDirtyDialog(onConfirm: Dialog.CallbackFunction, onCancel?: Dialog.CallbackFunction): void {
    useShowDialog({
        title: '¡Atención!',
        text: 'El documento tiene cambios sin guardar',
        confirmColor: 'red',
        confirmText: 'Descartar cambios',
        onConfirm: onConfirm,
        onCancel: onCancel,
    })
}

export function useShowPersistentDirtyDialog(onConfirm: Dialog.CallbackFunction, onDeny: Dialog.CallbackFunction, onCancel?: Dialog.CallbackFunction): void {
    useShowDialog({
        title: '¡Atención!',
        text: 'El documento tiene cambios sin guardar',
        confirmColor: 'red',
        confirmText: 'Borrar',
        denyColor: 'yellow',
        denyText: 'Mantener en borrador',
        onConfirm: onConfirm,
        onDeny: onDeny,
        onCancel: onCancel,
    })
}

export function useShowConfirmDialog(onConfirm: Dialog.CallbackFunction): void {
    useShowDialog({
        title: '¡Atención!',
        text: 'Confirme para continuar',
        confirmColor: 'yellow',
        confirmText: 'Continuar',
        onConfirm: onConfirm,
    })
}

export function useShowDeleteDialog(onConfirm: Dialog.CallbackFunction): void {
    useShowDialog({
        title: '¡Atención!',
        text: 'Confirme para eliminar',
        confirmColor: 'red',
        confirmText: 'Eliminar',
        onConfirm: onConfirm,
    })
}

export function useShowResetDialog(onConfirm: Dialog.CallbackFunction): void {
    useShowDialog({
        title: '¡Atención!',
        text: 'Confirme para descartar',
        confirmColor: 'yellow',
        confirmText: 'Descartar',
        onConfirm: onConfirm,
    })
}

export function useShowErrorDialog(title: string, message: string, onConfirm?: Dialog.CallbackFunction): void {
    useShowDialog({
        title: title,
        text: message,
        confirmColor: 'red',
        noCancelButton: true,
        onConfirm: onConfirm,
    })
}
