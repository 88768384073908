export const useCoreStore = defineStore('core', {
    state: () => ({
        version: process.env.APP_VERSION || 'DEVELOPMENT',
        darkMode: useDark(),
        appMounted: false,
        coreLoaded: false,
        vendedorAsignado: <App.Models.User | null>null,
        vendedores: <App.Models.User[]>[],
        clientes: <App.Models.User[]>[],
        rawTables: <NuevoPedido.Tables>[],
    }),

    getters: {
        isDark: (state) => state.darkMode,
        dataTableOperators: (): DataTable.Operators[] => [
            { operator: 'like', description: 'Contiene' },
            { operator: '=', description: 'Igual que' },
            { operator: '!=', description: 'No es igual que' },
        ],
        dataTableOperatorsExpanded(): DataTable.Operators[] {
            return [
                ...this.dataTableOperators,
                { operator: '>', description: 'Mayor que' },
                { operator: '<', description: 'Menor que' },
                { operator: '>=', description: 'Mayor o igual que' },
                { operator: '<=', description: 'Menor o igual que' },
            ]
        },
    },

    actions: {
        setAppMounted() {
            this.appMounted = true
        },

        toggleDark() {
            this.darkMode = !this.darkMode
        },

        async fetchCoreData(force = false) {
            if (!this.coreLoaded || force) {
                this.appMounted = false
                this.coreLoaded = false

                await window.axios
                    .get<Api.ApiCoreResponse>('/api/core')
                    .then(({ data }) => {
                        this.vendedorAsignado = data.vendedor_asignado
                        this.vendedores = data.vendedores
                        this.clientes = data.clientes
                        this.rawTables = data.pedidos_table

                        this.coreLoaded = true
                        this.setAppMounted()
                    })
                    .catch((error) => {
                        console.error(error)
                    })
            }
        },

        async cacheClear() {
            const authStore = useAuthStore()

            if (authStore.admin) {
                await window.axios
                    .get('/api/core/cache-clear')
                    .then(() => {
                        pushNotification.success('Cache purgado')
                    })
                    .catch((error) => {
                        console.error(error)
                    })
            }

            this.fetchCoreData(true)
        },
    },

    share: {
        enable: true,
    },
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useCoreStore, import.meta.hot))
}
